<template>
  <div class="vehicle-history">
    <Loading :active="loader" loader="dots" color="#4CAF50" style="position: absolute;">
    </Loading>
    <span class="vld-overlay is-active" :active="loader"
      :style="'top: 57px;color: #ffffff;background:rgba(0,0,0,0.5);font-size: 25px;display:' + (loader ? 'flex' : 'none')">
      {{ message }}</span>
    <div class="panel-inr-box">
      <div class="box-top-wrap d-flex align-center justify-space-between">
        <div>
          <h3 class="box-title">
            <span>Vehicle History Report</span>
          </h3>
        </div>
        <v-spacer v-if="$vuetify.breakpoint.width > 500"></v-spacer>
        <button class="btn customBtn pattern-btn
                " @click="$router.push({ name: 'PreviousVehicleHistory' })">
          <span>{{ $t("carHistory.previousCarHistory") }}</span>
        </button>
      </div>
      <div class="custom-card vehicle-car inside-model-box">
        <div class="custom-box">
          <div class="vehicle-car-search vehicle-r">
            <v-text-field v-model="vin" :error-messages="vinError" @blur="$v.vin.$touch()" elevation="0"
              @input="errors.vin = null" :loading="loading" dense solo
              :placeholder="$t('carHistory.cardHistorySearchSuffix')" maxlength="17"></v-text-field>
            <v-btn :disabled="!vin || ($v.vin.$invalid && $v.vin.$dirty)"
              class="btn customBtn transparent-btn white--text" width="40" elevation="0" @click="confirmation = true">
              <span><img src="./../assets/img/lucide_search.svg" alt="" /></span>
            </v-btn>
          </div>
          <div class="custom-chat-height">
            <div class="vehicle-desc">
              <h3>Premium VIN Decoder. $1</h3>
              <p>VIN Decoding is a complete premium technical specifications report for your vehicle.</p>
              <p class="link-with-para"><label class="semiBold-text">See a report's example:</label> <a
                  :href="api_endpoint + 'public/vin-decoder.pdf'" target="_blank">
                  <img src="./../assets/img/file-vector.svg" alt="" />
                  <span>Click here</span></a></p>
              <hr />
              <h3>New Car History Report $9 (Better than Carfax)</h3>
              <p>Our new Car History Report is provided within 2 reports. The Auction Report information (if car has
                been auctioned) with auction images, history, technical specifications, and title conditions and the VIN
                History Report, which is a more comprehensive report that includes auctions, accidents, recalls, history
                verification, inspections, odometer verification, damages, title, and sales history information. </p>
              <p class="link-with-para"><label class="semiBold-text">See a report's example:</label> <a
                  :href="api_endpoint + 'public/vin-report.pdf'" target="_blank">
                  <img src="./../assets/img/file-vector.svg" alt="" />
                  <span>Click here</span></a>
              </p>
              <hr />
            </div>
          </div>
        </div>
        <v-dialog hide-overlay v-model="confirmation" persistent max-width="500px" :attach="true" content-class="login-modal">
          <v-card>
            <v-card-title class="headline">
              <h4>Search Result</h4>
              <button class="close-btn" @click="confirmation = false">
                <img src="./../assets/img/close.svg" alt="">
              </button>
            </v-card-title>
            <v-card-text>
              <div class="search-div-wrapper">
                <div class="search-div-col">
                  <div class="search-div-inr">
                    <h4>Premium VIN Decoder. $1</h4>
                    <div class="text-center">
                      <v-btn height="45" class="btn customBtn pattern-btn bold-capitalize" text @click="search">
                        <span>Get Report at $1</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
                <div class="search-div-col">
                  <div class="search-div-inr">
                    <h4>New Car History Report $9.00 (Better than Carfax)</h4>
                    <div class="text-center">
                      <v-btn height="45" class="btn customBtn pattern-btn bold-capitalize" text @click="searchVinHtmlReport">
                        <span>Get Report at $9.00</span>
                      </v-btn>
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="btn customBtn red-fill-btn pattern-btn" @click="confirmation = false">
                <span> {{ $t("carHistory.cancelConfirmation") }}</span>
              </v-btn>
              <!-- <v-btn color="green darken-1" text @click="search">
                  {{ $t("carHistory.okConfirmation") }}
                </v-btn> -->
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { required } from "vuelidate/lib/validators";
import Loading from "vue-loading-overlay";

export default {
  components: {
    Loading: Loading,
  },
  data() {
    return {
      api_endpoint: process.env.VUE_APP_API_HOST,
      loading: false,
      loader: false,
      message: "",
      confirmation: false,
      responseMessage: "",
      response: false,
      vin: null,
      errors: {
        vin: null,
      },
    };
  },
  validations: {
    vin: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      vinHtmlReportInfo: "vehicleHistory/getVinHtmlReportInfo",
    }),
    vinError() {
      let error = null;
      if (this.$v.vin.$dirty && !this.$v.vin.required) {
        error = this.$t("requiredValidation", {
          field: this.$t("carHistory.vin"),
        });
      }
      if (this.errors && this.errors.vin) {
        error = this.errors.vin;
      }
      return error;
    },
  },
  methods: {
    ...mapActions({
      getVinHtmlReport: "vehicleHistory/getVinHtmlReport",
      getPremiumVinDecoderReport: "vehicleHistory/getPremiumVinDecoderReport",
    }),

    async searchVinHtmlReport() {
      this.confirmation = false;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        this.$v.$touch();
        try {
          this.loader = true;
          this.message = "System is generating Report, please wait few seconds...";
          await this.getVinHtmlReport({ vin_number: this.vin });
          if (this.vinHtmlReportInfo) {
            this.$router.push({ name: "VehicleHistoryResult" });
          }
        } catch ({ message }) {
          this.errors.vin = message;
        } finally {
          this.loading = false;
          this.loader = false;
          this.message = "";
        }
      }
    },

    async search() {
      this.confirmation = false;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading = true;
        this.$v.$touch();
        try {
          this.loader = true;
          this.message = "System is generating Report, please wait few seconds...";
          await this.getPremiumVinDecoderReport({ vin_number: this.vin });
          console.log(this.vinHtmlReportInfo);
          if (this.vinHtmlReportInfo.success) {
            this.$router.push({ name: "VehicleHistoryResult" });
          } else {
            this.errors.vin = this.vinHtmlReportInfo.message;
          }
        } catch ({ message }) {
          this.errors.vin = message;
        } finally {
          this.loading = false;
          this.loader = false;
          this.message = "";
        }
      }
    },
  },
};
</script>

<style scoped>
.v-dialog__content {
  position: absolute;
}
</style>
